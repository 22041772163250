.custom-about-block h4{
    font-family: JohnsonDisplay-Bold,sans-serif;
    color: #404040;
}

.custom-about-block .about-prof{
    color: #747474 !important;
}

.custom-about-block p{
    color: #454545;
}

.custom-about-block p span{
    font-family: JohnsonText-Bold,sans-serif;
}

.author-block-img .franklin_glorius{
   width: 100%;
}

.author-block .author-quote{
    color: #454545;
    text-align: center;
} 