
.Media-header .title-text{
    color: #EB1801;
    font-family: JohnsonDisplay-Bold,sans-serif;
}

.title-text {
    color: #EB1801;
}

.main-image .left-region {
    width: 70%;
}

.main-image .right-region {
    width: 30%;
}

.custom-picture .show-image {
    width: 69%;
}

.custom-picture .list-image {
    width: 41%;
}

.custom-video .show-video{
    width: 69%;
    height: auto;
}

.custom-video .list-video{
    width: 41%;
}

.custom-video .list-video .active{
    color: #EB1801;
}

.custom-video .list-video .non-active{
    color: #FFFFFF;
}

.custom-video .list-video .list_video .media-video-active{
    width: 100%;
}

.custom-video .list-video .list_video .media-video{
    width: 100%;
}

.media-images {
    width: 90px;
    height: 65px;
    float: left;
    margin: 0 10px 10px 0;
}

.media-images-active {
    width: 90px;
    height: 65px;
    float: left;
    border: 2px solid #EB1801;
    margin: 0 10px 10px 0;
}

.list_video .media-video-active {
    border: 2px solid #EB1801;
}

.show-image .current-image {
    width: 100%;
}

.Media-button .active {
    border: 1px;
    border-radius: 75px;
    color: #404040;
    background-color: #D5CFC9;
}

.Media-button button {
    border: 1px;
    border-radius: 75px;
    color: #404040;
    background-color: #FFFFFF;
    padding: 0px 30px;
}