
.custom-contact{
 width: 60%;   
}

.Contact-Main h4{
    font-family: JohnsonDisplay-Bold,sans-serif;
}
.custom-contact .left-region{
 width: 30%;   
}

.custom-contact .left-region .Hilmar_Weinmann{
 width: 75%;   
}

.custom-contact .right-region{
width: 70%;
}

.custom-contact .right-region p .p_header{
    font-family: JohnsonText-Bold,sans-serif;
}