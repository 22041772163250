.App-header{
    background: url("./../Assets/Images/HeaderImage.png") no-repeat, #EB1801;
    /* background: #EB1801; */
    background-position: right;
    background-size: 300px 150px;
    color: #FFFFFF;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.App-header-name{
    font-family: JohnsonDisplay-Medium, sans-serif !important;
}

#navbarNav .navbar-nav .nav-item{
    min-width:85px;
    border-radius:4px;
    text-align: center;
    color: #FFFFFF;
}

#navbarNav .navbar-nav .active{
    background: #FFFFFF;
    min-width:85px;
    border-radius:4px;
    text-align: center;
}


#navbarSocial .navbar-nav .nav-item a {
    color: #FFFFFF;
}


#navbarNav .navbar-nav .nav-item:hover{
    background: #FFFFFF;
    min-width:85px;
    border-radius:4px;
    text-align: center;
    color: #EB1801;
}

#navbarNav .navbar-nav .nav-item .nav-link{
    color: #FFFFFF;
    font-family: JohnsonText-Regular,sans-serif;
    padding: 4px;
}

#navbarNav .navbar-nav .nav-item .active{
    color: #EB1801;
    font-family: JohnsonText-Bold, sans-serif !important;
}


#navbarNav .navbar-nav .nav-item .nav-link:hover{
    color: #EB1801;
}


@media (min-width: 1200px)
{
    .custom-header {
        max-width: 1200px;
    }
}


/* @media (min-width: 768px)
{
    .custom-header {
        max-width: 768px;
    }
} */


/* @media (min-width: 992px)
{
    .custom-header {
        max-width: 992px;
    }
} */

