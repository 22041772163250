.Winner-Content{
        min-height: 600px; 
        height: max-content;
        display: flex;
        background-image: linear-gradient(to right, #F1EFED, #FFFFFF);
}

.Winner-Content .custom-nav{
    width: 25%;
}

.Winner-Content .custom-nav h4{
    color: #404040;
    font-family: JohnsonDisplay-Bold,sans-serif;
}

.Winner-Content .custom-nav ul{
    list-style: none;
    padding: 0;
}

.Winner-Content .customwinner-block{
    width: 75%;
    min-height: 600px; 
    background: #FFFFFF;
}

.Winner-Content .customwinner-block .left-region{
    width: 70%;
    float: left;
    min-height: 600px; 
    color: #454545;
}

.Winner-Content .customwinner-block .left-region h4{
    font-family: JohnsonDisplay-Bold,sans-serif;
}

.Winner-Content .customwinner-block .left-region .winning_dept{
    color: #747474;
}

.Winner-Content .customwinner-block .left-region .winning_year{
    color:#EB1801; 
    font-family: JohnsonDisplay-Bold,sans-serif;
}

.title-text{
    color:#EB1801; 
}

.Winner-Content .customwinner-block .right-region{
    width: 30%;
    float: left;
    min-height: 600px; 
}

.Winner-Content .customwinner-block .right-region .winner-image img{
    width: 100%;
}

.Winner-Content .customwinner-block .right-region .winner-quote{
    text-align: left;
    font-family: JohnsonDisplay-Regular,sans-serif;
    color: #454545;
}

.Winner-Content .customwinner-block .right-region .winner-quote h5{
    text-align: center;
    font-family: JohnsonDisplay-Bold,sans-serif;
    color: #454545;
}

.JohnsonDisplay-Bold{
    font-family: JohnsonDisplay-Bold,sans-serif !important;
}

.winner-nav-list .nav-left{
    width: 90%;
    /* float: left; */
}

.winner-nav-list .nav-left p{
    color: #404040;
    font-family: JohnsonDisplay-Bold,sans-serif;
    margin: 0;
}

.winner-nav-list .nav-left p:hover{
    color: #EB1801;
    font-family: JohnsonDisplay-Bold,sans-serif;
}
.nav-left p span{
    color: #747474;
    font-family: JohnsonText-Regular,sans-serif;
}

.winner-nav-list .nav-right{
    width: 10%;
    /* float: right; */
}

.winner-nav-list .nav-right .bi-arrow-right-circle-fill{
    color: #EB1801;
}