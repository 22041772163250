.Footer-Content{
    background-color: #F1EFED;
    color: #191919;
}

/* .Footer-Content .container h5{
    color: #EB1801;
} */

.Footer-Content .custom-minwidth .align-items-center .w-50 h5{
    color: #EB1801;
}