@font-face {
    font-family: 'JohnsonDisplay-Bold';
    src: local('JohnsonDisplay-Bold'),
         url('./JohnsonDisplay-Bold.ttf');
}

@font-face {
    font-family: 'JohnsonDisplayBoldItalic';
    src: local('JohnsonDisplay-BoldItalic'),
         url('./JohnsonDisplay-BoldItalic.ttf');
}

@font-face {
    font-family: 'JohnsonDisplayLight';
    src: local('JohnsonDisplay-Light'),
         url('./JohnsonDisplay-Light.ttf');
}

@font-face {
    font-family: 'JohnsonDisplayLightItalic';
    src: local('JohnsonDisplay-LightItalic'),
         url('./JohnsonDisplay-LightItalic.ttf');
}

@font-face {
    font-family: 'JohnsonDisplay-Medium';
    src: local('JohnsonDisplay-Medium'),
         url('./JohnsonDisplay-Medium.ttf');
}

@font-face {
    font-family: 'JohnsonDisplayMediumItalic';
    src: local('JohnsonDisplay-MediumItalic'),
         url('./JohnsonDisplay-MediumItalic.ttf');
}

@font-face {
    font-family: 'JohnsonDisplay-Regular';
    src: local('JohnsonDisplay-Regular'),
         url('./JohnsonDisplay-Regular.ttf');
}

@font-face {
    font-family: 'JohnsonDisplayRegularItalic';
    src: local('JohnsonDisplay-RegularItalic'),
         url('./JohnsonDisplay-RegularItalic.ttf');
}

@font-face {
    font-family: 'JohnsonText-Bold';
    src: local('JohnsonText-Bold'),
         url('./JohnsonText-Bold.ttf');
}

@font-face {
    font-family: 'JohnsonText-BoldItalic';
    src: local('JohnsonText-BoldItalic'),
         url('./JohnsonText-BoldItalic.ttf');
}

@font-face {
    font-family: 'JohnsonText-Light';
    src: local('JohnsonText-Light'),
         url('./JohnsonText-Light.ttf');
}

@font-face {
    font-family: 'JohnsonText-LightItalic';
    src: local('JohnsonText-LightItalic'),
         url('./JohnsonText-LightItalic.ttf');
}

@font-face {
    font-family: 'JohnsonText-Medium';
    src: local('JohnsonText-Medium'),
         url('./JohnsonText-Medium.ttf');
}

@font-face {
    font-family: 'JohnsonText-Regular';
    src: local('JohnsonText-Regular'),
         url('./JohnsonText-Regular.ttf');
}

@font-face {
    font-family: 'JohnsonText-RegularItalic';
    src: local('JohnsonText-RegularItalic'),
         url('./JohnsonText-RegularItalic.ttf');
}

