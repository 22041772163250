.History-Content{
    background-image: linear-gradient(to right, #FFFFFF, #F1EFED);
}

.d-flex .custom-history-block{
    width: 70%;
    background-color: #FFFFFF;
}

.d-flex .author-block{
    width: 30%;
}

.d-flex .author-block ul{
    padding: 0;
    list-style: none;
}

.d-flex .author-block ul li h6{
    font-family: JohnsonText-Bold,sans-serif;
    color: #454545;
}

.custom-history-block .title-text{
    color:#EB1801; 
    font-family: JohnsonDisplay-Bold, sans-serif;
}

.history-images{
    width: 50%;
}

.custom-history-block p .history-span{
    font-family: JohnsonText-Bold, sans-serif;
}

.custom-history-block p .history-link{
    color:#EB1801; 
    text-decoration: none;
}
