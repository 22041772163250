.custom-nominee .left-region .Prize2024 {
    background-color: #F1EFED;
}

.custom-nominee .left-region .Prize2024Bossxviii {
    background-color: #F1EFED;
}

.custom-nominee .left-region .Prize2024 h4 {
    font-family: JohnsonDisplay-Bold,sans-serif;
    color: #3A302C;
}

.custom-nominee .left-region .Prize2024 p {
    font-family: JohnsonText-Regular,sans-serif;
    color: #454545;
}

.BOSSXVIIISquareLogo_img {
    width: 60%;
}

.custom-nominee .right-region .p_header{
    font-family: JohnsonText-Bold,sans-serif;
}

.custom-nominee .right-region ul{
    padding-left: 1rem;
}

