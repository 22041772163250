.TheJury-Content {
    min-height: 600px;
    height: max-content;
    display: flex;
    background-image: linear-gradient(to right, #F1EFED, #FFFFFF);
}

.TheJury-Content .custom-nav {
    width: 25%;
}

.TheJury-Content .custom-nav h4 {
    color: #404040;
    font-family: JohnsonDisplay-Bold,sans-serif;
}

.TheJury-Content .custom-nav ul {
    list-style: none;
    padding: 0;
}

.TheJury-Content .customjury-block {
    width: 75%;
    background: #FFFFFF;
}

.TheJury-Content .customjury-block .left-region {
    width: 70%;
    float: left;
    min-height: 600px;
}

.TheJury-Content .customjury-block .left-region .summary_block{
   border-bottom: 1px solid #D8D3CE;
}

.TheJury-Content .customjury-block .left-region .past-jury h4{
    color: #EB1800;
    font-family: JohnsonDisplay-Bold,sans-serif;
 }

 .TheJury-Content .customjury-block .left-region .past-jury{
    color: #454545;
 }
 
 .TheJury-Content .customjury-block .left-region .past-jury .past-jury-list .row .col{
    padding: 0;
 }
 .TheJury-Content .customjury-block .left-region .past-jury .past-jury-list .row .col-5{
    padding: 0;
 }

.TheJury-Content .customjury-block .left-region .summary_block h4,h5 {
    font-family: JohnsonDisplay-Bold,sans-serif;
}
.TheJury-Content .customjury-block .left-region .summary_block h5 {
    color: #404040;
}

.TheJury-Content .customjury-block .left-region .winning_year {
    color: #EB1801;
}

.title-text {
    color: #EB1801;
}

.TheJury-Content .customjury-block .right-region {
    width: 30%;
    float: left;
    min-height: 600px;
}

.TheJury-Content .customjury-block .right-region .jury-image img {
    width: 100%;
}

.TheJury-Content .customjury-block .right-region .jury-quote {
    text-align: left;
}
.TheJury-Content .customjury-block .right-region .jury-quote h5{
    font-family: JohnsonDisplay-Bold,sans-serif;
    color: #454545;
}
.TheJury-Content .customjury-block .right-region .jury-quote p{
    font-family: JohnsonDisplay-Regular,sans-serif;
    color: #454545;
}

.jury-nav-list .nav-left {
    width: 90%;
    /* float: left; */
}

.jury-nav-list .nav-left .custom-jury-nav {
    color:#404040;
    font-family: JohnsonDisplay-Bold,sans-serif;
    margin: 0;
}

.jury-nav-list .nav-left .custom-jury-nav .custom-jury-span{
    color:#404040;
    font-family: JohnsonDisplay-Bold,sans-serif;
}

.jury-nav-list .nav-left .custom-jury-nav .custom-jury-about{
    color: #747474;
}

.jury-nav-list .nav-right {
    width: 10%;
    /* float: right; */
}

.jury-nav-list .nav-right .bi-arrow-right-circle-fill {
    color: #EB1801;
}