/* .home-info {
    text-align: justify; 
} */

.home-info .info-left {
    width: 50%;
    min-height: 100%;
}

.home-info .info-right {
    width: 50%;
    height: 100%;
    overflow: hidden;
    color: #191919;
}

.home-info .info-right .winner-block {
    background-color: #F1EFED;
    overflow: hidden;
    padding: 0 35px;
}

.home-info .info-right .winner-block h4 {
    font-family: JohnsonDisplay-Bold,sans-serif;
}

.home-info .info-right .winner-block .winner-images .symposium .symposium_img {
    width: 100%;
    display: block;
    height: auto;
}

.home-info .info-right .winner-block .winner-images .frank_glorius .franklin_img {
    width: 100%;
    display: block;
    height: auto;
}

.home-info .info-right .winner-block .winner-sub {
    width: 100%;
}

.home-info .info-right .winner-block .winner-sub .winner-sub-image{
    width: 40%;
}

.home-info .info-right .winner-block .winner-sub .winner-sub-image p span{
    font-family: JohnsonText-Bold,sans-serif;
}

.home-info .info-right .winner-block .winner-sub .winner-sub-image .melanie_sanford{
    width: 100%;
    height: auto;
}

.home-info .info-right .winner-block .winner-sub .winner-sub-content{
    width: 60%;
}

.info-right h4{
    color:#EB1801;
}

.home-info .info-left .aboutprize-block {
    overflow: hidden;
}

.info-left .aboutprize-block h4{
    color:#EB1801;
    font-family: JohnsonDisplay-Bold,sans-serif;
}

.info-left .aboutprize-block p span{
    font-family: JohnsonText-Bold,sans-serif;
}



.Container ul li{
    padding-bottom: 8px;
}

.Boss_Banner .bossbannser{
    width: 100%;
}

.home-info .info-right .nomination-block{
    margin-top: 10px;
    background-color: #F1EFED;;
    padding: 30px 30px 5px 30px;
}

.home-info .info-right .nomination-block h4{
    color:#EB1801;
    font-family: JohnsonDisplay-Bold,sans-serif;
}

.group216{
    width: 100px;
}